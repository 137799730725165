import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/scss/now-ui-kit.scss";
import "../src/style.css";
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);

const options = {
    confirmButtonColor: '#1fa8f5',
    cancelButtonColor: '#c92006',
};

app.use(VueSweetalert2, options);
app.use(router);
app.mount('#app');
